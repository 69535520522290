var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c("van-tab", { attrs: { title: "结算单" } }, [
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                Number(_vm.collectionStatementData.statementType) === 1
                  ? _c("paymentStatementPush", {
                      attrs: { "business-id": _vm.$route.query.collectionId }
                    })
                  : _c("paymentStatement", {
                      attrs: {
                        "detail-tables": _vm.settleList,
                        type: "payment"
                      }
                    })
              ],
              1
            )
          ]),
          _c(
            "van-tab",
            { attrs: { title: "收款信息" } },
            [_c("collectionInformation")],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "实际收款" } },
            [
              _c("collectionResults", {
                attrs: { "detail-tables": _vm.appDownList }
              })
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "相关附件" } },
            [
              _c("attachment-list", {
                attrs: {
                  "module-name": "settlement/collection",
                  "business-id": _vm.$route.query.collectionId
                }
              })
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "审核意见" } },
            [
              _c("workflow-audit", {
                attrs: {
                  "task-id": _vm.$route.query.taskId,
                  "page-key": "charge_apply",
                  "business-key": _vm.$route.query.collectionId,
                  "proc-inst-id": _vm.$route.query.procInstId,
                  variable: {
                    businessId: _vm.$route.query.collectionId,
                    motif:
                      _vm.collectionStatementData.payerOrgName +
                      "-" +
                      _vm.collectionStatementData.collectionMethodType +
                      "-" +
                      _vm.collectionStatementData.statementMoney
                  }
                },
                on: {
                  confirmAudit: function($event) {
                    return _vm.utils.backReturn(_vm.$route.query.messageType)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }