var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "van-main" }, [
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c(
          "van-cell-group",
          [
            _c("van-cell", {
              attrs: {
                title: "订单名称：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.orderName)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "订单编号：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.orderNo)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款方类型：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.payerType_Name)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款方：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.payerOrgName)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款方开户行：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.payerAccountBank)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款账号：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.paymentAccount)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款方类型：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.collectionMethodType_Name
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款方：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.beneficiaryOrgName
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款方开户行：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.beneficiaryAccountBank
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款账号：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.accountsReceivable
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款数量：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.collectionQuantity
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款方式：",
                value: _vm.utils.statusFormat(
                  _vm.appForm.collectionType,
                  "PaymentMethod"
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款总金额（元）：",
                value: _vm.utils.moneyFormat(_vm.appForm.collectionAmount || 0)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款备注：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.collectionNotes)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "是否同时申请开票：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.simultaneousBilling_Name
                )
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }