<template>
  <div class="van-main has-fixed-btn">
    <van-tabs v-model="active">
      <van-tab title="结算单">
        <div class="van-common-detail">
          <paymentStatementPush v-if="Number(collectionStatementData.statementType) === 1" :business-id="$route.query.collectionId" />
          <paymentStatement v-else :detail-tables="settleList" type="payment" />
        </div>
      </van-tab>
      <van-tab title="收款信息">
        <collectionInformation />
      </van-tab>
      <van-tab title="实际收款">
        <collectionResults :detail-tables="appDownList" />
      </van-tab>
      <van-tab title="相关附件">
        <attachment-list module-name="settlement/collection" :business-id="$route.query.collectionId" />
      </van-tab>
      <van-tab title="审核意见">
        <workflow-audit :task-id="$route.query.taskId" page-key="charge_apply" :business-key="$route.query.collectionId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:$route.query.collectionId,motif:`${collectionStatementData.payerOrgName}-${collectionStatementData.collectionMethodType}-${collectionStatementData.statementMoney}`}" @confirmAudit="utils.backReturn($route.query.messageType)" />
      </van-tab>
    </van-tabs>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, uploader } from 'vant'
import paymentStatement from '@/components/payment/payment-statement'
import collectionResults from '@/components/collection/collection-results'
import AttachmentList from '@/components/attachment-list'
import collectionInformation from '@/components/collection/collection-information'
import WorkflowAudit from '@/components/workflow-audit'
import BackButton from '@/components/back-button'
import paymentStatementPush from '@/components/payment/payment-pragmatic-push'
// import fileList from '@/components/file-list'
export default {
  components: {
    BackButton,
    paymentStatement,
    collectionResults,
    AttachmentList,
    collectionInformation,
    WorkflowAudit,
    paymentStatementPush,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [uploader.name]: uploader
    // fileList
  },
  data () {
    return {
      id: this.$route.query.id,
      statementType: this.$route.query.statementType,
      collectionStatementData: {},
      active: 0,
      contractForm: {
        signInfoList: [],
        fileDetailInfoVoApply: null,
        fileDetailInfoVoSeal: null
      },
      fileList: [],
      appDownList: [],
      settleList: {
        name: '1111'
      }
    }
  },
  computed: {
  },
  created () {
    this.getStatementDetail()
  },
  methods: {
    // 获取结算单详情
    getStatementDetail () {
      this.api.collection.statementDetail({ businessid: this.$route.query.collectionId }).then(result => {
        this.collectionStatementData = result.data.value
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
